import { setBusyAction, setErrorAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import { ReduxThunk } from "../../type";
import { addSurveyData } from "../../slices/survey";
import i18n from "../../../i18n";
import { CreateTokenPayload } from "../../types/survey";

const getErrorMessage = (msg: string): string => {
  if (msg === "LearnerAlreadyJoinedTournament") {
    return i18n.t("response.learnerJoinedTournament");
  } else if (msg === "CannotJoinTournamentAtThisTime") {
    return i18n.t("response.badTimingToJoinTournament");
  } else if (msg === "NotEnoughPoints") {
    return i18n.t("response.notEnoughPoints");
  } else if (msg === "LateTournamentSubmission") {
    return i18n.t("response.lateSubmission");
  } else if (msg === "TimeExpired") {
    return i18n.t("tournaments.timeExpired");
  } else {
    return i18n.t("response.errorCreatingToken");
  }
};

export const createToken: (payload: CreateTokenPayload) => ReduxThunk = ({
  apiRoute,
  ...payload
}) => {
  return async (dispatch, _, { client }) => {
    dispatch(setBusyAction({ key: AsyncActions.CreateToken, value: true }));
    try {
      const response = await client.post<{ token: string }>(apiRoute, payload);
      dispatch(
        addSurveyData({
          token: response.data.token,
        })
      );
    } catch (e: any) {
      dispatch(
        setErrorAction({
          key: AsyncActions.CreateToken,
          value: getErrorMessage(e?.response?.data?.message),
        })
      );
    }
    dispatch(setBusyAction({ key: AsyncActions.CreateToken, value: false }));
  };
};
