import tw, { styled } from "twin.macro";
import { ButtonStyled } from "../../../common/components/Button/Button";

export const Wrapper = styled.div(
  () => tw`flex flex-col items-center gap-y-2 font-sans w-full`
);

export const Title = styled.h1(() => tw`font-bold text-2xl font-dyslexic`);

export const Description = styled.div(
  () => tw`text-base font-sans text-center mb-9`
);

export const StyledParagraph = styled.p<{ bold?: boolean }>(({ bold }) => [
  tw`py-2 font-sans`,
  bold && tw`font-semibold`,
]);

export const Action = styled.div(
  () => tw`flex items-center justify-around gap-x-2`
);

export const ActionButton = styled(ButtonStyled)(
  () => tw`w-[20rem] md:w-[23rem]`
);

export const CancelTournamentButton = styled(ButtonStyled)(
  () => tw`hidden w-[12rem] md:block`
);

export const RoundedCancelTournamentButton = styled(ButtonStyled)(
  () => tw`rounded-full w-[2.7rem] md:hidden`
);

export const CancelModalButton = styled(ButtonStyled)(
  () => tw`w-[10rem] md:w-[11rem]`
);

export const IconWrapper = styled.div(() => tw`relative w-[15rem] h-[15rem]`);

export const Icon = styled.img(() => tw`absolute`);

export const CloseIconWrapper = styled.div(
  () => tw`w-full relative cursor-pointer`
);

export const CloseIcon = styled(Icon)(() => tw`top-8 right-8`);
