import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ResultWrapper,
  PageTitle,
  TestResultWrapper,
  ActionWrapper,
  PaperIcon,
} from "../../../components/LessonPage/Survey/styled";
import { ResultComponent } from "../../../components/LessonPage/Survey/ResultComponent";
import Button from "../Button/Button";
import { CompletedTournamentModal } from "../../../components/Tournament/modals/CompletedTournamentModal";

import papersIcon from "../../../assets/common/Survey/papers.svg";
import questionMarkIcon from "../../../assets/common/Survey/question_mark.svg";
import checkMarkIcon from "../../../assets/common/Survey/check-round.svg";
import cancelIcon from "../../../assets/common/Survey/cancel-round.svg";
import starIcon from "../../../assets/common/Learner-Home/Star.svg";

import { StoreState } from "../../../store/type";

import { reset } from "../../../store/slices/survey";
import { Spinner } from "../Spinner/Spinner";
import { useUserHomeRoute } from "../../hooks/useUserHomeRoute";

type Props = {
  isTournament?: boolean;
  pageTitle: string;
};

export const SurveyResult = ({ isTournament, pageTitle }: Props) => {
  const lesson = useSelector((state: StoreState) => state.subjects.lesson);

  const result = useSelector((state: StoreState) => state.survey.result);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const homeRoute = useUserHomeRoute();
  const [openModal, setOpenModal] = useState(false);
  const isSurveyDoneFirstTime = !lesson?.depository?.surveyDoneAt;

  const dispatch = useDispatch();

  const goToLesson = () => {
    navigate(isTournament ? homeRoute : "..");
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  if (!result) return <Spinner />;

  return (
    <ResultWrapper>
      <PaperIcon src={papersIcon} />
      <PageTitle>{pageTitle}</PageTitle>
      <TestResultWrapper showOnlyPoints={result.totalQuestions === 0}>
        <ResultComponent
          hide={result.totalQuestions === 0}
          result={result.totalQuestions}
          icon={questionMarkIcon}
          label={t("lessonPage.totalQuestions")}
        />
        <ResultComponent
          hide={result.totalQuestions === 0}
          result={result.correctAnswers}
          icon={checkMarkIcon}
          label={t("lessonPage.correctAnswers")}
        />
        <ResultComponent
          hide={result.totalQuestions === 0}
          result={result.wrongAnswers}
          icon={cancelIcon}
          label={t("lessonPage.wrongAnswers")}
        />
        {!isTournament && (
          <ResultComponent
            hide={!isSurveyDoneFirstTime}
            result={result.correctAnswers}
            icon={starIcon}
            label={t("lessonPage.points")}
          />
        )}
      </TestResultWrapper>
      <ActionWrapper>
        <Button
          onClick={isTournament ? handleOpenModal : goToLesson}
          buttonWidth={"14rem"}
        >
          {t("lessonPage.continue")}
        </Button>
      </ActionWrapper>
      {openModal && <CompletedTournamentModal onClose={closeModal} />}
    </ResultWrapper>
  );
};
