import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Description,
  ResultPendingSpinner,
  ResultPendingTitle,
  SurveyResultPageWrapper,
} from "./styled";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { useTranslation } from "react-i18next";

import { getSurveyResult } from "../../../store/thunks/Learner/getSurveyResult";
import { reset } from "../../../store/slices/survey";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";

export const SurveyResultPending = () => {
  const { t } = useTranslation();

  const { token, submissionId } = useParams();
  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const isTournament = params.get("turnir");

  useEffect(() => {
    if (submissionId && token) {
      dispatch(
        getSurveyResult(
          token,
          submissionId,
          isTournament
            ? ApiRoutes.PollTournamentSurveyResult
            : ApiRoutes.PollSurveyResult
        )
      );
    }
  }, [token, submissionId, isTournament]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <SurveyResultPageWrapper>
      <ResultPendingTitle>
        {t("lessonPage.surveyResultSubmitted")}
      </ResultPendingTitle>
      <Description>
        {t("lessonPage.surveyResultSubmittedDescription")}
      </Description>
      <ResultPendingSpinner>
        <Spinner />
      </ResultPendingSpinner>
    </SurveyResultPageWrapper>
  );
};
