import { useEffect, useRef, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, ResultWrapper } from "./styled";
import {
  surveyResultMessage,
  surveyErrorMessage,
} from "../../consts/iframe-message";
import { Spinner } from "../Spinner/Spinner";
import { SurveyResult as SurveyResultType } from "../../../store/types/survey";
import { useAsyncState } from "../../hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import {
  addSurveyResult,
  removeTokenFromStore,
} from "../../../store/slices/survey";
import { StoreState } from "../../../store/type";
import { SurveyResult } from "./SurveyResult";
import { SurveyError } from "./SurveyError";
import { TimerComponent } from "../Tournament/Timer";
import { LeaveTournamentModal } from "../../../components/Tournament/modals/LeaveTournamentModal";
import { showNotification } from "../../utils/toastNotification";
import { useUserHomeRoute } from "../../hooks/useUserHomeRoute";

type Props = {
  createToken: () => void;
  onSurveyResult?: (result: SurveyResultType) => void;
  url: string;
  tournamentEnd: string;
};

export const Survey = ({
  createToken,
  onSurveyResult,
  url,
  tournamentEnd,
}: Props) => {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { requestInProgress, error } = useAsyncState(AsyncActions.CreateToken);
  const { result, token } = useSelector((state: StoreState) => state.survey);
  const navigate = useNavigate();
  const homeRoute = useUserHomeRoute();
  const [numOfSrcChange, setNumOfSrcChange] = useState(0);

  const handleLoadEvent = () => {
    setNumOfSrcChange((pre) => pre + 1);
  };

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [ref.current]);

  useEffect(() => {
    createToken();
  }, [createToken]);

  useEffect(() => {
    return () => {
      dispatch(removeTokenFromStore());
    };
  }, []);

  useEffect(() => {
    const parseMessage = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) return;

      if (e?.data?.includes?.(surveyResultMessage)) {
        const result: SurveyResultType & {
          type: typeof surveyResultMessage;
        } = JSON.parse(e.data);
        dispatch(addSurveyResult(result));
        onSurveyResult?.(result);
      } else if (e?.data?.includes?.(surveyErrorMessage)) {
        const message: { type: typeof surveyErrorMessage; error: string } =
          JSON.parse(e.data);

        navigate(homeRoute);
        showNotification(message.error, "error");
      }
    };
    window.addEventListener("message", parseMessage);

    return () => {
      window.removeEventListener("message", parseMessage);
    };
  }, [onSurveyResult]);

  useEffect(() => {
    const parseSurveyResult = (e: MessageEvent) => {
      if (
        e.origin === window.location.origin &&
        e.data?.includes?.(surveyResultMessage)
      ) {
        const result: SurveyResultType & { type: typeof surveyResultMessage } =
          JSON.parse(e.data);
        dispatch(addSurveyResult(result));
      }
    };
    window.addEventListener("message", parseSurveyResult);

    return () => {
      window.removeEventListener("message", parseSurveyResult);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (result) {
    return (
      <SurveyResult
        isTournament
        pageTitle={t("tournaments.tournamentResult.title")}
      />
    );
  }
  if (error) return <SurveyError msg={error} />;

  return requestInProgress || !token ? (
    <ResultWrapper>
      <Spinner />
    </ResultWrapper>
  ) : (
    <Fragment>
      {numOfSrcChange < 2 && <TimerComponent date={tournamentEnd} />}
      <Wrapper
        onLoad={handleLoadEvent}
        ref={ref}
        src={`${url}?token=${token}`}
      />
      {numOfSrcChange < 2 && <LeaveTournamentModal />}
    </Fragment>
  );
};
