import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createToken } from "../../../store/thunks/Learner/createToken";
import { PageWrapper } from "../../../common/components/Survey/styled";
import { Survey } from "../../../common/components/Survey/Survey";
import { TournamentExpiredModal } from "../modals/TournamentExpiredModal";

import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { StoreState } from "../../../store/type";
import { SurveyError } from "../../../common/components/Survey/SurveyError";
import { getTournament } from "../../../store/thunks/Learner/tournament";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";
import { removeCurrentTournament } from "../../../store/slices/tournament";

export const IndividualTournament = () => {
  const { id: tournamentId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { requestInProgress } = useAsyncState(AsyncActions.FetchTournament);

  const tournament = useSelector(
    (state: StoreState) => state.tournaments.current
  );

  const survey = tournament?.survey;

  const handleCreateToken = useCallback(() => {
    // if tournament hasn't been cleared from store this is called with wrong tournament id
    if (!tournamentId || !survey?.id || tournament?.id !== tournamentId) return;
    dispatch(
      createToken({
        tournamentId,
        apiRoute: ApiRoutes.CreateIndividualTournamentToken,
        surveyId: survey.id,
      })
    );
  }, [tournamentId, survey?.id, tournament?.id]);

  useEffect(() => {
    if (!tournamentId) return;
    dispatch(getTournament(tournamentId));

    return () => {
      dispatch(removeCurrentTournament());
    };
  }, [tournamentId]);

  return (
    <PageWrapper>
      {requestInProgress ? (
        <></>
      ) : !survey ? (
        <SurveyError msg={t("tournaments.finished")} />
      ) : (
        <Survey
          createToken={handleCreateToken}
          url={survey.url}
          tournamentEnd={tournament.endsAt}
        />
      )}
      <TournamentExpiredModal endsAt={tournament?.endsAt} />
    </PageWrapper>
  );
};
