import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CancelTournamentButton,
  RoundedCancelTournamentButton,
  CancelModalButton,
} from "./styled";
import { CancelActionWrapper } from "../../../common/components/Tournament/styled";
import { useNavigate } from "react-router-dom";
import { useUserHomeRoute } from "../../../common/hooks/useUserHomeRoute";
import gif from "../../../assets/gifs/Cancel-Tournament.gif";
import { TournamentModal } from "./TournamentModal";
import { StoreState } from "../../../store/type";
import { setShowLeaveTournamentModal } from "../../../store/slices/survey";
import { clearRedirectState } from "../../../store/slices/router";
import { reset as resetSurveySlice } from "../../../store/slices/survey";

export const LeaveTournamentModal = () => {
  const show = useSelector(
    (state: StoreState) => state.survey.showLeaveTournamentModal
  );
  const originalRoute = useSelector(
    (state: StoreState) => state.router.redirectTo
  );
  const dispatch = useDispatch();
  const homeRoute = useUserHomeRoute();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const leave = () => {
    navigate(originalRoute || homeRoute);
    dispatch(clearRedirectState());
    dispatch(resetSurveySlice());
  };
  const stay = () => {
    dispatch(setShowLeaveTournamentModal(false));
    dispatch(clearRedirectState());
  };

  const showModal = () => dispatch(setShowLeaveTournamentModal(true));

  const descriptions = [
    { text: t("tournaments.modal.leave.subtitle"), bold: true },
    { text: t("tournaments.modal.leave.description") },
  ];

  return (
    <CancelActionWrapper>
      {!show ? (
        <>
          <CancelTournamentButton invert onClick={showModal}>
            {t("tournaments.leaveTournament")}
          </CancelTournamentButton>
          <RoundedCancelTournamentButton invert onClick={showModal}>
            X
          </RoundedCancelTournamentButton>
        </>
      ) : (
        <TournamentModal
          onClose={stay}
          title={t("tournaments.modal.leave.title")}
          descriptions={descriptions}
          icon={gif}
        >
          <CancelModalButton buttonWidth={"9.125rem"} invert onClick={stay}>
            {t("tournaments.modal.leave.stayLabel")}
          </CancelModalButton>
          <CancelModalButton buttonWidth={"9.125rem"} onClick={leave}>
            {t("tournaments.modal.leave.leaveLabel")}
          </CancelModalButton>
        </TournamentModal>
      )}
    </CancelActionWrapper>
  );
};
