import { SurveyResult as Result } from "../types/survey";
import {
  surveyErrorMessage,
  surveyResultMessage,
} from "../../common/consts/iframe-message";

export const sendSurveyResult = (result: Result) => {
  window.parent.postMessage(
    JSON.stringify({ type: surveyResultMessage, ...result }),
    window.location.origin
  );
};

export const sendSurveyResultError = (error: string) => {
  window.parent.postMessage(
    JSON.stringify({ type: surveyErrorMessage, error }),
    window.location.origin
  );
};
