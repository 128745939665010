export enum AsyncActions {
  LoginStudentAction = "loginStudentAction",
  LoginParentAction = "loginParentAction",
  RegisterAction = "registerAction",
  ResendOTPAction = "resendOTP",
  ConfirmCodeAction = "confirmCodeAction",
  FetchUserAction = "fetchUserAction",
  AddLearnersAction = "addLearnersAction",
  OTPAction = "otpAction",
  FetchSchoolsAction = "fetchSchoolsAction",
  FetchClassroomsAction = "fetchClassroomsAction",
  GetEstimatedPayingAction = "getEstimatedPayingAction",
  PayByCodeSuccessful = "payByCodeSuccessful",
  RenewByCodeSuccessful = "renewByCodeSuccessful",
  PayByCardSuccessful = "payByCardSuccessful",
  RenewByCardSuccessful = "renewByCardSuccessful",
  PostPaymentMethod = "postPaymentMethod",
  FetchSubjectsAction = "fetchSubjectsAction",
  FetchSubjectLessonsAction = "fetchSubjectLessonsAction",
  FetchLessonInfoAction = "fetchLessonInfoAction",
  AddDepository = "addDepository",
  DownloadNotes = "downloadNotes",
  DownloadTasks = "downloadTasks",
  DownloadSolutions = "downloadSolutions",
  ChangePinAction = "changePinAction",
  EditParentInfoAction = "editParentInfoAction",
  FetchPaymentMethods = "fetchPaymentMethod",
  SendResetLink = "sendResetLink",
  ResetPassword = "resetPassword",
  FetchAvatars = "fetchAvatars",
  FetchTraits = "fetchTraits",
  PatchUsername = "patchUsername",
  PatchGender = "patchGender",
  PatchAvatar = "patchAvatar",
  PostTrait = "postTrait",
  PatchTraits = "patchTraits",
  FetchUserTraits = "fetchUserTraits",
  FetchAvailableSubjects = "fetchAvailableSubjects",
  FetchFavoriteSubjects = "fetchFavoriteSubjects",
  PatchFavoriteSubjects = "patchFavoriteSubjects",
  RemoveLearner = "removeLearner",
  FetchPoints = "FetchPoints",
  Authorization = "authorization",
  FetchLearnerActivity = "fetchLearnerActivity",
  FetchLearnersProgress = "FetchLearnersProgress",
  FetchPaymentHistory = "FetchPaymentHistory",
  FetchPaymentReceipt = "FetchPaymentReceipt",
  ChangeFont = "ChangeFont",
  CreateToken = "CreateToken",
  GetSurveyResult = "GetSurveyResult",
  SendCodeToPhone = "SendCodeToPhone",
  SearchLessons = "SearchLessons",
  UpcomingTournaments = "GetUpcomingTournaments",
  RecentTournaments = "GetRecentTournaments",
  FetchTournamentResult = "FetchTournamentResult",
  FetchTournamentsForSponsor = "FetchTournamentsForSponsor",
  FetchTournamentResultsForSponsor = "FetchTournamentResultsForSponsor",
  TournamentUsernameConsent = "TournamentUsernameConsent",
  FetchTournament = "FetchTournament",
  FetchAvatarItems = "FetchAvatarItems",
  BuyItem = "BuyItem",
  ChangeItemStatus = "ChangeItemStatus",
  RefundItem = "RefundItem",
  CancelStripeSubscription = "CancelStripeSubscription",
  GetStripeSubscriptions = "GetStripeSubscriptions",
  CreateFreeItems = "CreateFreeItems",
  Assistant = "Assistant",
}
