import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import dayjs from "../../config/dayjs";
import {
  ActionLabel,
  ActionWrapper,
  Collapse,
  IconWrapper,
  Item,
  ItemDescription,
  ItemValue,
  MobileTournamentItem,
  Panel,
  PanelContent,
  PanelItemDescription,
  PanelItemValue,
  StarIcon,
  TabletTournamentWrapper,
  TournamentClosed,
  TournamentIcon,
  Timer,
  TournamentTitle,
  TournamentType,
} from "./styled";
import cupIcon from "../../../assets/common/Lesson-Page/Cup.svg";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";

import { Tournament } from "../../../store/types/tournament";
import { useTournamentsSchedule } from "./useTournamentsSchedule";
import { StartTournamentButton } from "./StartTournamentButton";
import { getDuration } from "./utils";

const PanelContentComponent = ({
  pointsRequired,
  startsAt,
  endsAt,
  id,
  isSubjectPoints,
  subjectCode,
  openModal,
  points,
  participated,
  name,
}: Tournament & { openModal: (subjectName: string | null) => void }) => {
  const { t } = useTranslation();
  const { isTournamentClosed, isWarningTime, timeToTournamentStart } =
    useTournamentsSchedule(startsAt, t("tournaments.ongoing"));

  return (
    <PanelContent>
      <PanelItemDescription>
        {t("tournaments.pastTournamentDetails.duration")}:
      </PanelItemDescription>
      <PanelItemValue>{getDuration(endsAt, startsAt)}</PanelItemValue>
      <PanelItemDescription>
        {t("tournaments.pastTournamentDetails.startsAt")}:
      </PanelItemDescription>
      <PanelItemValue>{dayjs(startsAt).format("DD MMM/HH:mm")}</PanelItemValue>
      <PanelItemDescription>
        {t("tournaments.pastTournamentDetails.pointsRequired")}:
      </PanelItemDescription>
      <PanelItemValue>
        <StarIcon src={starIcon} />
        {pointsRequired}
      </PanelItemValue>
      <PanelItemDescription>
        {t("tournaments.pastTournamentDetails.rewards")}:
      </PanelItemDescription>
      <PanelItemValue>
        <StarIcon src={starIcon} />
        {points}
      </PanelItemValue>
      <PanelItemDescription>
        {t("tournaments.pastTournamentDetails.startsIn")}:
      </PanelItemDescription>
      <PanelItemValue>
        {isTournamentClosed(endsAt) ? (
          <TournamentClosed>
            {t("tournaments.pastTournamentDetails.closed")}
          </TournamentClosed>
        ) : (
          <Timer warning={isWarningTime(timeToTournamentStart)}>
            {timeToTournamentStart}
          </Timer>
        )}
      </PanelItemValue>
      <ActionLabel>
        {t("tournaments.pastTournamentDetails.startTournament")}:
      </ActionLabel>
      <ActionWrapper>
        <StartTournamentButton
          participated={participated}
          id={id}
          startsAt={startsAt}
          pointsRequired={pointsRequired}
          isSubjectPoints={isSubjectPoints}
          subjectCode={subjectCode}
          openModal={openModal}
          endsAt={endsAt}
          name={name}
        />
      </ActionWrapper>
    </PanelContent>
  );
};

export const UpcomingTournamentList = ({
  tournaments,
  openModal,
}: {
  tournaments: Tournament[];
  openModal: (subjectName: string | null) => void;
}) => {
  const { t } = useTranslation();
  const [activePanel, setActivePanel] = useState<string>("");

  const handleSetActivePanel = (val: string) => () => {
    setActivePanel((pre) => (pre === val ? "" : val));
  };

  return (
    <Collapse activeKey={activePanel} accordion={true} destroyInactivePanel>
      {tournaments.map(({ id, ...rest }) => (
        <Panel
          collapsible="header"
          key={id}
          header={
            <Fragment>
              {/*mobile*/}
              <MobileTournamentItem onClick={handleSetActivePanel(id)}>
                <IconWrapper>
                  <TournamentIcon src={cupIcon} />
                </IconWrapper>
                <Item>
                  <ItemValue>
                    {rest.name || t("tournaments.tournament")}
                  </ItemValue>
                  <ItemDescription extraSmall>
                    {t("tournaments.pastTournamentDetails.individual")}
                  </ItemDescription>
                </Item>
              </MobileTournamentItem>
              {/*tablet*/}
              <TabletTournamentWrapper onClick={handleSetActivePanel(id)}>
                <TournamentTitle>
                  <IconWrapper>
                    <TournamentIcon src={cupIcon} />
                  </IconWrapper>
                  {rest.name || t("tournaments.tournament")}
                </TournamentTitle>
                <TournamentType>
                  {t("tournaments.pastTournamentDetails.individual")}
                </TournamentType>
              </TabletTournamentWrapper>
            </Fragment>
          }
        >
          <PanelContentComponent openModal={openModal} id={id} {...rest} />
        </Panel>
      ))}
    </Collapse>
  );
};
