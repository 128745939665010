import tw, { styled, css } from "twin.macro";
import colors from "../../colors.json";
import { ReactComponent as CloseIcon } from "../../assets/common/close.svg";
import { ReactComponent as BackIcon } from "../../assets/common/Lesson-Page/Back.svg";
import { ReactComponent as _SearchIcon } from "../../assets/common/Header/Search.svg";

import { ButtonStyled } from "../../common/components/Button/Button";

export const AssistantWrapper = styled.div(
  () => tw`fixed right-4 bottom-4 lg:right-20 lg:bottom-20 z-50`
);

export const AssistantModalOuterWrapper = styled.div<{ animate: boolean }>(
  ({ animate }) => [
    [
      tw`origin-center_bottom rounded-t-[1.875rem] rounded-br-[1.875rem] w-[5.125rem] h-[6.19rem] border-[1.5px] border-black  cursor-pointer shadow-assistant float-right`,
      animate && tw`animate-wiggle`,
    ],
  ]
);

export const AssistantModalInnerWrapper = styled.div(
  () =>
    tw`w-[5rem] h-[6.1rem] bg-gold rounded-t-[1.75rem] rounded-br-[1.75rem] border-[1.5px] border-white`
);

export const BubbleOuterWrapper = styled.div(() => [
  tw`w-[3.35rem] h-[3.35rem] rounded-b-[1.78rem] rounded-tr-[1.78rem] border-[1.5px] border-black 
    cursor-pointer shadow-assistant float-right`,
]);

export const BubbleInnerWrapper = styled.div(
  () =>
    tw`bg-black w-[51px] h-[51px] rounded-b-[1.75rem] rounded-tr-[1.75rem] border-[1.5px] border-white flex items-center justify-center`
);

export const CloseIconComponent = styled(CloseIcon)(() => [
  tw`h-[0.75rem] w-[0.75rem]`,
  css`
    path {
      stroke: white !important;
    }
  `,
]);

export const AssistantDialogWrapper = styled.div<{ bgGray: boolean }>(
  ({ bgGray }) => [
    tw`max-w-[95%] w-[18.75rem] h-[28.75rem] shadow-assistant rounded-3xl bg-white mb-1 flex flex-col`,
    bgGray && tw`bg-cream`,
  ]
);

export const AssistantDialogTitleWrapper = styled.div(
  () => tw`bg-dark-blue py-3 pl-4 rounded-3xl flex gap-x-4 rounded-bl-[0px]`
);

export const TitleBackIcon = styled(BackIcon)(() => [
  tw`w-[1.2rem] h-[1.2rem] cursor-pointer`,
  css`
    path {
      fill: white;
    }
  `,
]);

export const AssistantDialogTextWrapper = styled.div(() => ``);

export const AssistantDialogDescription = styled.div(
  () => tw`font-sans font-semibold text-white text-xs opacity-70`
);

export const AssistantDialogTitle = styled.div(
  () => tw`font-sans font-bold text-base text-white`
);

export const AssistantDialogSelectedOptionTitle = styled.div(
  () => tw`font-semibold text-sm font-sans text-white`
);

export const AssistantDialogAvatarWrapper = styled.div(
  () => tw`w-9 h-9 bg-white rounded-full`
);

export const AssistantDialogContentWrapper = styled.div(() => [
  tw`px-4 overflow-auto [::-webkit-scrollbar]:w-2 [::-webkit-scrollbar-track]:bg-transparent [::-webkit-scrollbar-corner]:h-0 
    [::-webkit-scrollbar-thumb]:bg-dark-gray [::-webkit-scrollbar-thumb]:rounded-lg flex flex-col`,
  css`
    flex-grow: 1;
  `,
]);

export const AssistantDialogContentDescription = styled.div(
  () => tw`font-semibold text-sm text-black mb-2 pt-4`
);

export const DialogOption = styled.div(
  () =>
    tw`rounded-xl p-3 bg-cream border border-dark-gray flex gap-x-4 items-center cursor-pointer mb-1.5`
);

export const DialogOptionIcon = styled.img(() => tw`w-8 h-8`);

export const DialogOptionText = styled.p(
  () => tw`text-sm font-medium text-black`
);

export const ArrowWrapper = styled.div(() => [
  tw`flex justify-end`,
  css`
    flex-grow: 1;
  `,
]);

export const ArrowIcon = styled.img(() => tw`w-4 h-4`);

// LessonSummary
export const ResultWrapper = styled.div(
  () => tw`font-sans text-sm pb-4 leading-5 font-normal whitespace-pre-wrap`
);
export const LessonSummaryIcon = styled.img(() => tw`w-8 h-8`);
export const LessonSummaryMetaWrapper = styled.div(() => tw`my-4`);

// HelpMessage
export const HelpMessageWrapper = styled.div(
  () =>
    tw`bg-dark-blue text-white text-center text-sm font-medium flex gap-x-2 px-3 py-2.5 rounded-r-full rounded-full mb-[1px] flex items-center cursor-pointer`
);

export const CloseHelpMessageIcon = styled(CloseIcon)(() => [
  tw`w-4 h-4`,
  css`path {stroke: ${colors["gray"]}`,
]);

export const LoadingWrapper = styled.div(() => tw`my-8 float-right`);

// QnA
export const NextQuestionBtn = styled(ButtonStyled)(
  () => tw`w-[10rem] font-sans float-right`
);

export const Question = styled.span(
  () => tw`font-sans text-black font-semibold mb-4 block`
);
export const Answer = styled.span(
  () => tw`font-sans text-black font-medium mb-2 block`
);

export const QnAWrapper = styled(ResultWrapper)(() => tw`pb-20`);

// Related lessons

export const RelatedLessonLink = styled.p(
  () => tw`mb-4 cursor-pointer underline text-dark-blue`
);

// Search

export const AssistantSearchOuterWrapper = styled.div(() => tw``);
export const AssistantSearchWrapper = styled.div(() => [
  tw`font-sans text-black text-sm flex flex-col justify-between`,
  css`
    flex-grow: 1;
  `,
]);

export const AssistantSearchResultWrapper = styled(ResultWrapper)();

export const TermWrapper = styled.div(() => tw`mb-4 font-sans`);

export const SearchInputWrapper = styled.div(
  () => tw`h-[2.5rem] flex mx-[-12px] mb-[16px] overflow-hidden`
);

export const SearchButton = styled(ButtonStyled)(() => [
  tw`rounded-r-lg w-[3rem]`,
  css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `,
]);

export const SearchInput = styled.input(() => [
  [
    tw`focus:outline-none h-full focus:border-dark-blue border border-dark-gray 
    rounded-l-lg px-3 font-sans placeholder:text-black placeholder:opacity-60 
    placeholder:font-sans placeholder:font-normal placeholder:text-sm font-sans mr-auto`,
    css`
      flex-grow: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `,
  ],
]);

// SearchResult

export const SearchResultWrapper = styled.div(() => tw`mb-2`);

export const SearchResultTitle = styled.div(
  () => tw`font-sans font-semibold text-sm mb-2 text-dark-blue underline`
);
export const SearchResultSummary = styled.div(
  () => tw`font-sans font-medium text-xs`
);

export const NoResult = styled.div(
  () => tw`font-sans text-base font-bold text-black`
);

export const AssistantHelpMessage = styled.span(() => tw``);

export const SearchIcon = styled(_SearchIcon)(
  () =>
    css`
      path {
        stroke: white;
      }
    `
);
