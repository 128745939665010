import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import notesIcon from "../../../../assets/common/Lesson-Page/Notes.svg";
import questionsIcon from "../../../../assets/common/Lesson-Page/Questions.svg";
import answersIcon from "../../../../assets/common/Lesson-Page/Answers.svg";
import doneIcon from "../../../../assets/common/Lesson-Page/Done.svg";
import testIcon from "../../../../assets/common/Lesson-Page/test.svg";

import {
  BtnDiv,
  BtnIcon,
  DoneImage,
  ResourcesHeading,
  WrapperDiv,
} from "./LessonResourcesStyled";
import Button from "../../../../common/components/Button/Button";

import { StoreState } from "../../../../store/type";
import {
  downloadAnswersAction,
  downloadNotesAction,
  downloadQuestionsAction,
} from "../../../../store/thunks/Learner/depository";
import { downloadAction } from "../../../../store/slices/subject";
import { useAsyncState } from "../../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../../store/enums/AsyncActions";
import { ErrorModal } from "./ErrorModal";
import { BtnSpinner } from "../../../../common/components/BtnSpinner/BtnSpinner";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../common/consts/route-path";
import { env } from "../../../../common/config/env";

export const LessonResources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { depository, survey } = useSelector(
    (state: StoreState) => state.subjects.lesson
  );
  const { font } = useSelector((state: StoreState) => state.user.info);

  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const { requestInProgress: notesInProgress, error: notesError } =
    useAsyncState(AsyncActions.DownloadNotes);

  const { requestInProgress: questionsInProgress, error: questionsError } =
    useAsyncState(AsyncActions.DownloadTasks);

  const { requestInProgress: answersInProgress, error: answersError } =
    useAsyncState(AsyncActions.DownloadSolutions);

  const downloadLink = useSelector(
    (state: StoreState) => state.subjects.download?.value
  );

  const surveyId = survey ? survey.externalSurveyId : null;
  const surveyLink = survey ? survey.url : null;
  const hasTest = !!surveyId && !!surveyLink;

  const downloadInProgress =
    answersInProgress || questionsInProgress || notesInProgress;

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const downloadNotesHandler = () => {
    if (downloadInProgress) return;
    dispatch(downloadNotesAction(depository?.id, font));
  };

  const downloadQuestionsHandler = () => {
    if (downloadInProgress) return;
    dispatch(downloadQuestionsAction(depository?.id, font));
  };

  const downloadAnswersHandler = () => {
    if (downloadInProgress) return;
    dispatch(downloadAnswersAction(depository?.id, font));
  };

  const startSurvey = () => {
    if (!surveyId || !surveyLink || downloadInProgress) return;
    navigate(`../${RoutePath.lessonPage.sub.survey.locale[env.locale]}`);
  };

  useEffect(() => {
    if (downloadLink) {
      const a = document.createElement("a");
      a.href = downloadLink;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      dispatch(downloadAction(null));
    }
  }, [downloadLink]);

  useEffect(() => {
    if (notesError || questionsError || answersError) {
      setErrorModalOpen(true);
    }
  }, [questionsError, notesError, answersError]);

  return (
    <WrapperDiv>
      <ResourcesHeading>{t("lessonPage.resourcesHeading")}</ResourcesHeading>
      <BtnDiv>
        <Button
          success={!!depository?.notesDownloadedAt}
          alignLeft
          onClick={downloadNotesHandler}
          sm
          invert
          buttonWidth="100%"
          bottomMargin="1rem"
          resPending={answersInProgress || questionsInProgress}
        >
          <BtnIcon
            src={notesIcon}
            filterEffect={answersInProgress || questionsInProgress}
          />
          {t("lessonPage.notesBtn")}
          {depository?.notesDownloadedAt && <DoneImage src={doneIcon} />}
          {notesInProgress && <BtnSpinner />}
        </Button>
      </BtnDiv>
      <BtnDiv>
        <Button
          success={!!depository?.tasksDownloadedAt}
          alignLeft
          onClick={downloadQuestionsHandler}
          sm
          invert
          buttonWidth="100%"
          bottomMargin="1rem"
          resPending={notesInProgress || answersInProgress}
        >
          <BtnIcon
            src={questionsIcon}
            filterEffect={notesInProgress || answersInProgress}
          />
          {t("lessonPage.questionsBtn")}
          {depository?.tasksDownloadedAt && <DoneImage src={doneIcon} />}
          {questionsInProgress && <BtnSpinner />}
        </Button>
      </BtnDiv>
      <BtnDiv>
        <Button
          success={!!depository?.solutionsDownloadedAt}
          alignLeft
          onClick={downloadAnswersHandler}
          sm
          invert
          buttonWidth="100%"
          bottomMargin="1rem"
          resPending={questionsInProgress || notesInProgress}
        >
          <BtnIcon
            src={answersIcon}
            filterEffect={questionsInProgress || notesInProgress}
          />
          {t("lessonPage.answersBtn")}
          {depository?.solutionsDownloadedAt && <DoneImage src={doneIcon} />}
          {answersInProgress && <BtnSpinner />}
        </Button>
      </BtnDiv>
      {hasTest && (
        <BtnDiv>
          <Button
            success={!!depository?.surveyDoneAt}
            alignLeft
            onClick={startSurvey}
            sm
            invert
            buttonWidth="100%"
            bottomMargin="1rem"
            resPending={downloadInProgress}
          >
            <BtnIcon src={testIcon} filterEffect={downloadInProgress} />
            {t("lessonPage.takeTest")}
            {depository?.surveyDoneAt && <DoneImage src={doneIcon} />}
          </Button>
        </BtnDiv>
      )}
      {errorModalOpen && <ErrorModal closeErrorModal={closeErrorModal} />}
    </WrapperDiv>
  );
};
