import { useMemo, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Action } from "./styled";
import { Tournament } from "../../../store/types/tournament";
import { StoreState } from "../../../store/type";
import { getRoute } from "../../utils/getRoute";
import { RoutePath } from "../../consts/route-path";
import { env } from "../../config/env";
import { ViewTournamentResult } from "./ViewTournamentResult";
import dayjs from "../../config/dayjs";
import { CannotStartTournamentModal } from "../../../components/Tournament/modals/CannotStartTournamentModal";

type Props = Pick<
  Tournament,
  | "startsAt"
  | "subjectCode"
  | "isSubjectPoints"
  | "pointsRequired"
  | "id"
  | "endsAt"
  | "participated"
  | "name"
> & { openModal: (subjectName: string | null) => void };

export const StartTournamentButton = ({
  subjectCode,
  isSubjectPoints,
  pointsRequired,
  id,
  openModal,
  endsAt,
  startsAt,
  participated,
  name,
}: Props) => {
  const { t } = useTranslation();

  const [cannotStartReason, setCannotStartReason] = useState<false | string>(
    false
  );
  const { totalPoints, pointsBySubject } = useSelector(
    (state: StoreState) => state.user.info
  );
  const navigate = useNavigate();

  const canParticipate: boolean = useMemo(() => {
    if (isSubjectPoints && subjectCode && pointsRequired) {
      return pointsRequired <= pointsBySubject[subjectCode];
    }
    if (pointsRequired) {
      return pointsRequired <= totalPoints;
    }
    return true;
  }, [pointsBySubject, totalPoints, pointsRequired, isSubjectPoints, name]);

  const label = canParticipate
    ? t("tournaments.pastTournamentDetails.start")
    : t("tournaments.pastTournamentDetails.participate");

  const startTournament = () => {
    if (participated) {
      setCannotStartReason(
        t("tournaments.modal.cannotStart.reason.participated")
      );
      return;
    }
    if (dayjs(startsAt).isAfter(new Date())) {
      setCannotStartReason(
        t("tournaments.modal.cannotStart.reason.time", {
          time: dayjs(startsAt).format("HH:mm"),
        })
      );
      return;
    }

    navigate(
      getRoute(
        { id },
        RoutePath.tournaments.locale[env.locale],
        RoutePath.tournaments.sub.individualTournaments.locale[env.locale]
      )
    );
  };

  const handleOpenModal = () => {
    openModal(subjectCode);
  };

  const handleCloseStartTournamentModal = () => {
    setCannotStartReason(false);
  };

  if (dayjs(endsAt).isBefore(new Date()))
    return <ViewTournamentResult id={id} />;

  return (
    <Fragment>
      <Action onClick={canParticipate ? startTournament : handleOpenModal}>
        {participated ? t("tournaments.submitted") : label}
      </Action>
      {cannotStartReason && (
        <CannotStartTournamentModal
          onClose={handleCloseStartTournamentModal}
          reason={cannotStartReason}
        />
      )}
    </Fragment>
  );
};
