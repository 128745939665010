import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ResultWrapper, Wrapper } from "./styled";
import { StoreState } from "../../../store/type";
import { markSurveyAsDone } from "../../../store/thunks/Learner/depository";
import {
  surveyErrorMessage,
  surveyResultMessage,
} from "../../../common/consts/iframe-message";
import { createToken } from "../../../store/thunks/Learner/createToken";
import { Spinner } from "../../../common/components/Spinner/Spinner";
import { fetchLessonInfo } from "../../../store/thunks/Learner/fetchLessons";
import { SurveyResult as SurveyResultType } from "../../../store/types/survey";
import { addSurveyResult } from "../../../store/slices/survey";
import { useUserHomeRoute } from "../../../common/hooks/useUserHomeRoute";
import { showNotification } from "../../../common/utils/toastNotification";
import { SurveyResult } from "../../../common/components/Survey/SurveyResult";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { SurveyError } from "../../../common/components/Survey/SurveyError";
import { useAsyncState } from "../../../common/hooks/useAsyncState";
import { AsyncActions } from "../../../store/enums/AsyncActions";

export const Survey = () => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLIFrameElement | null>(null);
  const { subjectCode, lessonCode } = useParams();
  const navigate = useNavigate();
  const homeRoute = useUserHomeRoute();
  const result = useSelector((state: StoreState) => state.survey.result);
  const { error } = useAsyncState(AsyncActions.CreateToken);
  const { t } = useTranslation();

  const lesson = useSelector((state: StoreState) => state.subjects.lesson);

  const { token } = useSelector((state: StoreState) => state.survey);

  const isSurveyDoneFirstTime = !lesson?.depository?.surveyDoneAt;

  const pageTitle = isSurveyDoneFirstTime
    ? t("lessonPage.resultPageTitle")
    : t("lessonPage.surveyDoneAnotherTime");

  const depositoryId = lesson.depository?.id;
  const surveyId = lesson?.survey?.externalSurveyId;
  const surveyLink = lesson?.survey?.url;

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [ref.current]);

  useEffect(() => {
    if (surveyId && lesson.code) {
      dispatch(
        createToken({
          lessonCode: lesson.code,
          surveyId,
          apiRoute: ApiRoutes.CreateToken,
        })
      );
      return;
    }
  }, [lesson, surveyId]);

  useEffect(() => {
    const parseSurveyResult = (e: MessageEvent) => {
      if (e.origin !== window.location.origin) return;
      if (e.data?.includes?.(surveyResultMessage)) {
        const result: SurveyResultType & { type: typeof surveyResultMessage } =
          JSON.parse(e.data);
        dispatch(addSurveyResult(result));

        if (result && depositoryId) {
          dispatch(markSurveyAsDone(depositoryId));
        }
      } else if (e.data?.includes?.(surveyErrorMessage)) {
        const message: { type: typeof surveyErrorMessage; error: string } =
          JSON.parse(e.data);

        navigate(homeRoute);
        showNotification(message.error, "error");
      }
    };
    window.addEventListener("message", parseSurveyResult);

    return () => {
      window.removeEventListener("message", parseSurveyResult);
    };
  }, [depositoryId, homeRoute]);

  useEffect(() => {
    if (!lesson.code && subjectCode && lessonCode) {
      dispatch(fetchLessonInfo({ subjectCode, lessonCode }));
    }
  }, [lesson, subjectCode, lessonCode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (result) return <SurveyResult pageTitle={pageTitle} />;

  if (error)
    return (
      <ResultWrapper>
        <SurveyError msg={error} />
      </ResultWrapper>
    );

  return !token || !surveyLink ? (
    <ResultWrapper>
      <Spinner />
    </ResultWrapper>
  ) : (
    <Wrapper ref={ref} src={`${surveyLink}?token=${token}`} />
  );
};
