import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "rc-dropdown";
import { NotificationLabel, NotificationWrapper } from "./styled";
import { AlertDropdown } from "./AlertDropdown";

import { env } from "../../../config/env";

import { fetchNotifications } from "../../../../store/thunks/Learner/fetchNotifications";
import { StoreState } from "../../../../store/type";
import { addAlert } from "../../../../store/slices/notification";
import { getItem } from "../../../utils/localStorageService";
import { LocalStorageKeys } from "../../../enums/LocalStorageKeys";
import { Icon, IconDiv } from "../HeaderStyled";
import alertSvg from "../../../../assets/common/Header/Alert.svg";

export const Alert = () => {
  const notifications = useSelector((state: StoreState) => state.notifications);
  const [numOfNotifications, setNumOfNotifications] = useState<number>(0);
  const { username, activeClass } = useSelector(
    (state: StoreState) => state.user.info
  );
  const dispatch = useDispatch();

  const handleAlert = (e: MessageEvent<unknown>) => {
    try {
      dispatch(addAlert(JSON.parse(e.data as string)));
      setNumOfNotifications((pre) => pre + 1);
    } catch (e) {
      console.log(e);
    }
  };

  const clearNotifications = (opened: boolean) => {
    if (opened) {
      setNumOfNotifications(0);
    }
  };

  useEffect(() => {
    setNumOfNotifications(
      notifications.reduce((acc, cur) => acc + (cur.seen ? 0 : 1), 0)
    );
  }, [notifications]);

  useEffect(() => {
    if (!username || !activeClass?.id) return;
    const token = getItem(LocalStorageKeys.TOKEN_KEY);
    const eventSource = new EventSource(
      `${env.baseUrl}/notification/alert?token=${token}`,
      {
        withCredentials: false,
      }
    );

    eventSource.addEventListener("message", handleAlert);

    return () => {
      eventSource.removeEventListener("message", handleAlert);
      eventSource.close();
    };
  }, [activeClass?.id, username]);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  return (
    <Dropdown
      onVisibleChange={clearNotifications}
      trigger={"click"}
      animation="slide-up"
      overlay={<AlertDropdown />}
    >
      <IconDiv>
        <NotificationWrapper>
          {numOfNotifications > 0 && (
            <NotificationLabel>{numOfNotifications}</NotificationLabel>
          )}
          <Icon src={alertSvg} />
        </NotificationWrapper>
      </IconDiv>
    </Dropdown>
  );
};
