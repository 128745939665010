import { useMemo } from "react";
import { omit } from "ramda";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { any } from "lodash/fp";
import {
  AlertItemWrapper,
  CircleIcon,
  Icon,
  IconWrapper,
  Message,
  TimeLabel,
} from "./styled";
import { IconDiv } from "../HeaderStyled";
import circleIcon from "../../../../assets/common/Header/Circle.svg";
import dayjs from "../../../../common/config/dayjs";
import {
  Notification,
  NotificationKind,
  TournamentEnd,
  TournamentStart,
} from "../../../../store/types/notification";
import tournamentIcon from "../../../../assets/common/Header/tournament.svg";
import { markNotificationAsRead } from "../../../../store/thunks/Learner/markNotificationsAsRead";
import { redirect } from "../../../../store/slices/router";
import { getRoute } from "../../../utils/getRoute";
import { RoutePath } from "../../../consts/route-path";
import { env } from "../../../config/env";

export const TournamentItem = ({
  json: { id, name, date },
  kind,
  createdAt,
  seen,
  id: notificationId,
}: TournamentStart | TournamentEnd) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  const translation = t(
    `header.alert.${
      kind === NotificationKind.TournamentStart
        ? "tournamentStart"
        : "tournamentEnd"
    }`,
    { name, date: dayjs(date).format("DD MMM/HH:mm") }
  );

  const alertRelativeDate = useMemo(() => dayjs().from(createdAt), [createdAt]);

  const goTo = () => {
    const isGoingToSameTournament = any(
      (s: string) => s.includes(id),
      Object.values(params)
    );
    if (isGoingToSameTournament) {
      return;
    }
    dispatch(
      redirect({
        to: getRoute(
          { id },
          RoutePath.tournaments.locale[env.locale],
          kind === NotificationKind.TournamentStart
            ? RoutePath.tournaments.sub.individualTournaments.locale[env.locale]
            : RoutePath.tournaments.sub.result.locale[env.locale]
        ),
      })
    );
  };

  const markAsSeen = () => {
    goTo();
    if (seen) return;
    dispatch(markNotificationAsRead(notificationId));
  };

  return (
    <AlertItemWrapper
      eventKey={notificationId}
      key={notificationId}
      onClick={markAsSeen}
      seen={seen}
    >
      <IconWrapper>
        {!seen && <CircleIcon src={circleIcon} />}
        <IconDiv>
          <Icon src={tournamentIcon} />
        </IconDiv>
      </IconWrapper>
      <Message bold={true}>{translation}</Message>
      <TimeLabel>{alertRelativeDate}</TimeLabel>
    </AlertItemWrapper>
  );
};

export const AlertItem = ({ kind, ...rest }: Notification) => {
  switch (kind) {
    case NotificationKind.TournamentStart:
      return <TournamentItem kind={kind} {...omit([], rest)} />;
    case NotificationKind.TournamentEnd:
      return <TournamentItem kind={kind} {...rest} />;
    default:
      return <></>;
  }
};
