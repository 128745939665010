import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RoutePath } from "../../../common/consts/route-path";
import { env } from "../../../common/config/env";

import { StoreState } from "../../../store/type";

import * as BannerStyled from "./BannerStyled";
import backIcon from "../../../assets/common/Lesson-Page/Back.svg";
import starIcon from "../../../assets/common/Lesson-Page/Star.svg";
import { calculateEarnedPoints } from "../../../common/utils/calculateLessonPoints";

export const Banner = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const subjectName = useSelector(
    (state: StoreState) => state.subjects.lesson.subject?.name
  );

  const subjectCode = useSelector(
    (state: StoreState) => state.subjects.lesson.subject?.code
  );

  const lessonName = useSelector(
    (state: StoreState) => state.subjects.lesson.name
  );

  const { depository, points, surveyPoints } = useSelector(
    (state: StoreState) => state.subjects.lesson
  );

  const { total } = calculateEarnedPoints(points, depository);

  const navigateFn = () => {
    navigate(
      `${RoutePath.home.locale[env.locale]}${
        subjectCode ? `?subjectCode=${subjectCode}` : ""
      }`
    );
  };

  return (
    <BannerStyled.BannerWrapperStyled>
      <BannerStyled.BackBtnDiv>
        <BannerStyled.BackBtn>
          <BannerStyled.BackIcon onClick={navigateFn} src={backIcon} />
        </BannerStyled.BackBtn>
        <BannerStyled.SubjectNameDiv onClick={navigateFn}>
          <BannerStyled.SubjectSpan>
            {t("lessonPage.subjectHeading")}:
          </BannerStyled.SubjectSpan>
          <BannerStyled.SubjectName>{subjectName}</BannerStyled.SubjectName>
        </BannerStyled.SubjectNameDiv>
      </BannerStyled.BackBtnDiv>
      <BannerStyled.LessonName>{lessonName}</BannerStyled.LessonName>
      <BannerStyled.PointsDivStyled>
        <BannerStyled.PointsHeading>
          {t("lessonPage.pointsHeading")}
        </BannerStyled.PointsHeading>
        <BannerStyled.InnerPointsDivStyled>
          <BannerStyled.PointsImageStyled src={starIcon} />
          <BannerStyled.PointsStyled>{total}</BannerStyled.PointsStyled>
          <BannerStyled.TotalPointStyled>
            {`/${points}`}
          </BannerStyled.TotalPointStyled>
          {surveyPoints !== null && (
            <Fragment>
              <BannerStyled.SurveyPointsWrapper>
                {`  +  `}
              </BannerStyled.SurveyPointsWrapper>
              <BannerStyled.PointsImageStyled src={starIcon} />
              <BannerStyled.TotalPointStyled>
                {surveyPoints}
              </BannerStyled.TotalPointStyled>
            </Fragment>
          )}
        </BannerStyled.InnerPointsDivStyled>
      </BannerStyled.PointsDivStyled>
    </BannerStyled.BannerWrapperStyled>
  );
};
