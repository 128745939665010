import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { StoreState } from "../../store/type";
import { clearRedirectState } from "../../store/slices/router";
import { RoutePath } from "../../common/consts/route-path";
import { env } from "../../common/config/env";
import { getRoute } from "../../common/utils/getRoute";
import { setShowLeaveTournamentModal } from "../../store/slices/survey";

export const RouteWatcher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserOnTournamentPage = !!useMatch(
    getRoute(
      {},
      RoutePath.tournaments.locale[env.locale],
      RoutePath.tournaments.sub.individualTournaments.locale[env.locale]
    )
  );

  const { result: tournamentResult, token } = useSelector(
    (state: StoreState) => state.survey
  );

  const redirectTo = useSelector(
    (state: StoreState) => state.router.redirectTo
  );

  useEffect(() => {
    // user didn't get result and token exist (because of refresh)
    if (redirectTo) {
      if (isUserOnTournamentPage && !tournamentResult && !!token) {
        dispatch(setShowLeaveTournamentModal(true));
        return;
      }
      navigate(redirectTo);
      dispatch(clearRedirectState());
    }
  }, [redirectTo, token, tournamentResult, isUserOnTournamentPage]);

  return <></>;
};
