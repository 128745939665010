import { ReduxThunk } from "../../type";
import { SurveyResult } from "../../types/survey";
import { ApiRoutes } from "../../../common/enums/ApiRoutes";
import { setBusyAction } from "../../slices/async";
import { AsyncActions } from "../../enums/AsyncActions";
import i18n from "../../../i18n";
import {
  sendSurveyResult,
  sendSurveyResultError,
} from "../../utils/parentIframeMessages";

const getErrorMessage = (msg: string): string => {
  if (msg === "LearnerAlreadyJoinedTournament") {
    return i18n.t("response.learnerJoinedTournament");
  } else if (msg === "CannotJoinTournamentAtThisTime") {
    return i18n.t("response.badTimingToJoinTournament");
  } else if (msg === "NotEnoughPoints") {
    return i18n.t("response.notEnoughPoints");
  } else if (msg === "LateTournamentSubmission") {
    return i18n.t("response.lateSubmission");
  } else if (msg === "TimeExpired") {
    return i18n.t("tournaments.timeExpired");
  } else {
    return i18n.t("response.errorSubmittingTournament");
  }
};

export const getSurveyResult: (
  token: string,
  submissionId: string,
  route: ApiRoutes.PollSurveyResult | ApiRoutes.PollTournamentSurveyResult
) => ReduxThunk =
  (token, submissionId, route) =>
  async (dispatch, _, { client }) => {
    dispatch(
      setBusyAction({ key: AsyncActions.GetSurveyResult, value: false })
    );
    try {
      const response = await client.get<SurveyResult>(
        `${route}/${token}/${submissionId}`
      );
      sendSurveyResult(response.data);
    } catch (e: any) {
      sendSurveyResultError(getErrorMessage(e?.response?.data?.message));
    }
    dispatch(
      setBusyAction({ key: AsyncActions.GetSurveyResult, value: false })
    );
  };
