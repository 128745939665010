import { useSelector } from "react-redux";
import { AlertItem } from "./AlertItem";
import { DropdownHeader } from "./DropdownHeader";
import { StyledMenu } from "./styled";
import { StoreState } from "../../../../store/type";

export const AlertDropdown = () => {
  const notifications = useSelector((state: StoreState) => state.notifications);

  return (
    <StyledMenu>
      {notifications.length > 0 && <DropdownHeader />}
      {notifications.map((d) => (
        <AlertItem {...d} key={d.id} />
      ))}
    </StyledMenu>
  );
};
