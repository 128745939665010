import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../enums/SliceNames";
import { SurveyState } from "../types/survey";

const initialState: SurveyState = {
  token: null,
  result: null,
  showLeaveTournamentModal: false,
};

const surveySlice = createSlice({
  name: SliceNames.Survey,
  initialState,
  reducers: {
    addSurveyData: (
      state,
      { payload }: PayloadAction<Pick<Required<SurveyState>, "token">>
    ) => {
      Object.assign(state, payload);
    },
    addSurveyResult: (
      state,
      { payload }: PayloadAction<SurveyState["result"]>
    ) => {
      state.result = payload;
    },
    setShowLeaveTournamentModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showLeaveTournamentModal = payload;
    },
    removeTokenFromStore: (state) => {
      state.token = null;
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  addSurveyData,
  reset,
  addSurveyResult,
  setShowLeaveTournamentModal,
  removeTokenFromStore,
} = surveySlice.actions;

export default surveySlice.reducer;
