import tw, { styled, css } from "twin.macro";
import Menu from "rc-menu";

export const NotificationWrapper = styled.div(
  () => tw`relative w-8 h-full flex items-center justify-center`
);

export const NotificationLabel = styled.div<{ noPadding?: boolean }>(
  ({ noPadding }) => [
    tw`absolute flex items-center w-max top-0 z-10 bg-black text-white font-sans px-2 rounded-[3.125rem] font-bold text-xxs`,
    noPadding && tw`px-1`,
  ]
);

export const AlertItemWrapper = styled(Menu.Item)<{ seen?: boolean }>(
  ({ seen }) => [
    tw`grid bg-white !pr-4 shadow-md md:w-[24.5rem] cursor-pointer`,
    css`
      grid-template-columns: 64px 1fr;
      grid-template-rows: 50px 1fr;
      border-bottom: 1px solid #e4e8ee;
    `,
    !seen && tw`bg-blue-notification`,
  ]
);
export const Message = styled.div<{ bold?: boolean }>(({ bold }) => [
  tw`col-start-2 font-sans text-sm pt-4`,
  bold && tw`font-bold`,
]);

export const TimeLabel = styled.div(
  () => tw`font-sans text-smoke col-start-2 text-sm self-center pb-4`
);

export const IconWrapper = styled.div(() => tw`relative p-4`);
export const Icon = styled.img(() => tw`w-[1.19rem] h-[1.19rem]`);
export const CircleIcon = styled.img(
  () => tw`absolute top-[0.4rem] left-[0.4rem]`
);
export const StyledMenu = styled(Menu)(() => tw`!shadow-none !border-none`);

// DropdownHeader
export const DropdownHeaderWrapper = styled(Menu.Item)(() => [
  tw`flex py-3 justify-between items-center bg-white !py-3 !px-4`,
]);

export const HeaderDescription = styled.div(
  () => tw`font-sans text-sm text-dark-11`
);

export const ActionWrapper = styled.div(
  () => tw`flex gap-x-2 text-dark-blue font-sans text-sm cursor-pointer`
);

export const CheckIcon = styled.img();
